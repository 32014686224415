import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { getNavItems } from 'selectors/navigationSelectors'
import { rem } from 'polished'
import { accountAccessShowLogin } from 'actions/UiActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import NavigationPhoneMenu from './NavigationPhoneMenu'
import NavigationRegionMenu from './NavigationRegionMenu'
import { hasAccountNotification, selectLoggedIn } from 'selectors/accountSelectors'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import FormatCurrency from 'components/Common/FormatCurrency'
import ListItem from 'components/Luxkit/List/ListItem'
import config from 'constants/config'
import BusinessTravellerAccountListItemMenu from 'businessTraveller/components/business-account/BusinessTravellerAccountListItemMenu'
import Modal from 'components/Luxkit/Modal/Modal'
import Group from 'components/utils/Group'
import Caption from 'components/Luxkit/Typography/Caption'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { themeClassName } from 'lib/theme/themeUtils'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import Divider from 'components/Luxkit/Divider'
import NavigationContainer from './NavigationContainer'

const LoginBlock = styled.div`
  > * + * {
    margin-left: ${rem(12)};
  }
`

const StyledTextButton = styled(TextButton)`
  width: 100%;
`

interface Props {
  navItems: Array<App.NavItem>;
  isLoggedIn?: boolean;
  hasNotification: boolean;
  firstName?: string;
  lastName?: string;
  balance: number;
  canRedeemLuxPlusBenefits: boolean;
}

function NavigationMenu(props: Props) {
  const {
    navItems,
    hasNotification,
    isLoggedIn,
    firstName,
    lastName,
    balance,
    canRedeemLuxPlusBenefits,
  } = props

  const dispatch = useAppDispatch()
  const modalElement = useModalElementContext<App.NavItem | undefined>()

  const showLogin = useCallback(() => {
    dispatch(accountAccessShowLogin())
    modalElement.resolve(undefined)
  }, [dispatch, modalElement])

  const onModalClose = useCallback(() => {
    modalElement.resolve(undefined)
  }, [modalElement])

  const primaryNavItems = useMemo(() => navItems.filter(navItem => navItem.component !== 'secondary'), [navItems])
  const secondaryNavItems = useMemo(() => navItems.filter(navItem => navItem.component == 'secondary'), [navItems])

  return <Modal title="" mode="menu" height="full" className={themeClassName('inverse')}>
    <Group direction="vertical" gap={20} verticalAlign="space-between" fullHeight>
      <Group direction="vertical" gap={8} data-testid="NavigationMenu">
        <NavigationContainer navItems={primaryNavItems} />
        {!!secondaryNavItems.length && <>
          <Divider kind="primary"/>
          <NavigationContainer navItems={secondaryNavItems} />
        </>}
        <Divider kind="primary"/>
        <NavigationPhoneMenu onClick={onModalClose} />
        <Divider kind="primary"/>
        <NavigationRegionMenu />
        {isLoggedIn && config.businessTraveller.isEnabled && <BusinessTravellerAccountListItemMenu />}
        {isLoggedIn && !config.businessTraveller.isEnabled && (
          <ListItem
            onClick={() => modalElement.resolve(undefined)}
            to="/account"
            title={canRedeemLuxPlusBenefits ? LUXURY_PLUS.PROGRAM_NAME : 'Account'}
            subtitle={balance > 0 ? <FormatCurrency value={balance} format="dynamicDollar" /> : undefined }
            aria-label="Account"
            titleColour={canRedeemLuxPlusBenefits ? 'loyalty' : undefined}
            startIcon={
              <ProductPaletteProvider palette={canRedeemLuxPlusBenefits ? 'loyalty' : 'default'}>
                <AvatarCircle
                  firstName={firstName}
                  lastName={lastName}
                  size="small"
                  variant="dark"
                  nonInteractable
                  hasNotification={hasNotification}
                />
              </ProductPaletteProvider>
            }
          />
        )}
        {!isLoggedIn && (
          <LoginBlock>
            <StyledTextButton
              kind="secondary"
              size="large"
              onClick={showLogin}
              role="menuitem"
            >
              Sign up or login
            </StyledTextButton>
          </LoginBlock>
        )}
      </Group>

      {config.MOBILE_APP_CUSTOMISATIONS && config.MOBILE_APP_VERSION && (
        <Group direction="horizontal" horizontalAlign="end">
          <Caption variant="large">v{config.MOBILE_APP_VERSION}</Caption>
        </Group>
      )}
    </Group>
  </Modal>
}

function mapStateToProps(state: App.State) {
  return {
    navItems: getNavItems(state),
    isLoggedIn: selectLoggedIn(state),
    hasNotification: hasAccountNotification(state),
    firstName: state.auth.account.givenName,
    lastName: state.auth.account.surname,
    balance: state.auth.account.balance,
    canRedeemLuxPlusBenefits: checkCanRedeemLuxPlusBenefits(state),
  }
}

export default connect(mapStateToProps)(NavigationMenu)
