import React, { MouseEventHandler, useCallback, useMemo, useRef } from 'react'
import NavigationNavItem from './NavigationNavItem'
import ListItem from 'components/Luxkit/List/ListItem'
import StatusLabel from 'components/Luxkit/Label/StatusLabel'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import { useIsMobileScreen } from 'lib/web/deviceUtils'

interface Props {
  currentActiveNavItem?: App.NavItem
  isOpen: boolean
  navItem: App.NavItem
  onClose: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  onSubNavClick: (selection: string, position: number) => void
  onToggle: (navItem: App.NavItem) => void
  position: number;
}

function NavigationNavDropdownItem(props: Props) {
  const {
    currentActiveNavItem,
    isOpen,
    navItem,
    onClose,
    onSubNavClick,
    onToggle,
    position,
  } = props

  const navItemRef = useRef<HTMLButtonElement>(null)
  const isMobile = useIsMobileScreen()

  const handleToggle = useCallback(() => {
    onToggle(navItem)
  }, [navItem, onToggle])

  const handleSubNavClick = useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>, subNavPosition: number) => {
    onSubNavClick(e.currentTarget.id, position + subNavPosition)
    // after use clicks and navigates, design wants our 'active' indicator, not our focus state
    e.currentTarget.blur()
  }, [onSubNavClick, position])

  const Content = useMemo(() => navItem.items?.map((item, index) => (
    <ListItem
      size={isMobile ? 'large' : 'medium'}
      key={item.id}
      id={item.id}
      aria-label={`Select ${item.text}`}
      onClick={(e) => handleSubNavClick(e, index)}
      to={item.to}
      href={item.href}
      title={item.text}
      target={item.target}
      className={item.linkClass}
      endIcon={item.newLabel && <StatusLabel variant="confirmation">NEW</StatusLabel>}
      selected={item === currentActiveNavItem}
    />
  )), [currentActiveNavItem, handleSubNavClick, navItem.items, isMobile])

  return <>
    <NavigationNavItem
      ref={navItemRef}
      navItem={navItem}
      onClick={handleToggle}
      open={isOpen}
      active={navItem === currentActiveNavItem}
      position={position}
    />

    <CSSBreakpoint max="mobile">
      <ModalBase isOpen={isOpen && isMobile} onClose={onClose}>
        <ModalHeader title="More" onCloseButtonClick={onClose}/>
        <ModalBody>
          <ModalContent>
            {Content}
          </ModalContent>
        </ModalBody>
      </ModalBase>
    </CSSBreakpoint>

    <CSSBreakpoint min="desktop">
      <DropdownList
        size="S"
        anchorRef={navItemRef}
        triggerRef={navItemRef}
        open={isOpen}
        onClose={onClose}
      >
        {Content}
      </DropdownList>
    </CSSBreakpoint>
  </>
}

export default NavigationNavDropdownItem
